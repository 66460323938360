document.addEventListener("turbo:load", function() {
    if (document.querySelector('.home-intro__circle-wrapper') != null) {
        document.querySelector('.home-intro__circle-wrapper .card').addEventListener('click', () => {
            if (document.querySelector('.home-intro__circle-wrapper .card').classList.contains('flipped')) {
                document.querySelector('.home-intro__circle-wrapper .card').classList.remove('flipped');
            } else {
                document.querySelector('.home-intro__circle-wrapper .card').classList.add('flipped');
            }
        })
    }
})
