document.addEventListener("turbo:load", function() {
    document.querySelectorAll('.fact-circle').forEach((title) => {
        title.addEventListener('mouseover', () => {
            title.classList.add('looping')
        });

        title.addEventListener('mouseout', () => {
            title.classList.remove('looping')
        });
    });
});

