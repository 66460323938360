import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'

export default class extends Controller {
    static targets = ["calendar", "loader"];
    static values = { parkId: Number, tripId: Number, initialDate: String }

    connect() {
        let loader = this.loaderTarget;
        let calendar = new Calendar(this.calendarTarget, {
            plugins: [dayGridPlugin],
            firstDay: 1,
            contentHeight: "auto",
            aspectRatio: 1.5,
            eventSources: this.eventSources(),
            startParam: 'start',
            endParam: 'end',
            showNonCurrentDates: true,
            initialDate: this.initialDate(),
            loading: function (isLoading) {
                if (isLoading) {
                    loader.style.display = 'flex'
                } else {
                    loader.style.display = 'none'
                }
            },
            headerToolbar: {
                start:   'title',
                center: 'prevYear,nextYear',
                end:  'today prev,next'
            },
            eventClick: function(info) {

            }
        });

        calendar.render();
    }

    initialDate() {
        if (this.hasInitialDateValue) {
            return this.initialDateValue;
        }
    }

    eventSources() {
        if (this.hasTripIdValue && this.hasInitialDateValue) {
            return [
                {
                    url: '/api/trips/' + this.tripIdValue + '.json?show_visits=0&for_calendar=1',
                    display: 'background'
                },
                {
                    url: '/api/visits.json?trip=' + this.tripIdValue,
                    display: 'block'
                }
            ]
        } else if (this.hasParkIdValue && this.hasInitialDateValue) {
            return [
                {
                    url: '/api/visits.json?park=' + this.parkIdValue,
                    display: 'block'
                }
            ]
        } else {
            return [
                {
                    url: '/api/trips.json',
                    display: 'background'
                },
                {
                    url: '/api/visits.json',
                    display: 'block'
                }
            ]
        }
    }
}
