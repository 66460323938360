import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [""];

  connect() {
    let paramString = window.location.href.split('?')[1];
    let queryString = new URLSearchParams(paramString);

    if (queryString.has('show-soundtrack-player')) {
        this.element.classList.add('show-soundtrack-player');
    }
  }
}
