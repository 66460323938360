document.addEventListener("turbo:load", function() {
    function positionDropdownNavigations() {
        document.querySelectorAll('.header__primary-nav > ul > li > ul').forEach(submenu => {
            submenu.style.display = 'block';
            var submenu_width = submenu.offsetWidth;
            var submenu_parent_left = submenu.parentElement.getBoundingClientRect().left;
            var left_of_parent_to_right_of_window_width = window.innerWidth - submenu_parent_left;

            submenu.style.display = '';

            if (submenu_width > left_of_parent_to_right_of_window_width) {
                var difference = submenu_width - left_of_parent_to_right_of_window_width;

                submenu.style.left = submenu.style.left - difference - 16 + 'px';
            }
        });
    }

    positionDropdownNavigations();

    window.addEventListener('resize', e => {
        positionDropdownNavigations();
    });
});
