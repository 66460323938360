document.addEventListener("turbo:load", function() {
    document.querySelectorAll('.field input, .field textarea').forEach(input => {
        input.addEventListener('blur', (e) => {
            let inputField = e.target

            if (inputField.value.length != 0) {
                inputField.classList.add('used')
            } else {
                inputField.classList.remove('used')
            }
        })
    })
})
