import { Controller } from "@hotwired/stimulus";

let searchActive = false;
const keycodeEsc = 27;

export default class extends Controller {
    static targets = ["toggle", "overlay"];

    connect() {
        document.addEventListener("keyup", this.handleKeyPress.bind(this));
    }

    handleKeyPress(event) {
        if (event.keyCode === keycodeEsc) {
            this.toggle();
        }
    }

    toggle() {
        if (searchActive) {
            this.overlayTarget.classList.remove('is-visible');
            this.overlayTarget.style.display = 'none';
            searchActive = false;
        } else {
            this.overlayTarget.style.display = 'flex';
            setTimeout(() => {
                    this.overlayTarget.classList.add('is-visible');
                },
                100
            )
            setTimeout(() => {
                    this.overlayTarget.querySelector('[type="search"]').value = '';
                    this.overlayTarget.querySelector('[type="search"]').focus();
                },
                200
            )
            searchActive = true;
        }
    }
}
