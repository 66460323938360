document.addEventListener("turbo:load", function() {
    const button = document.querySelector('[href="#top-of-page"]');

    const showOrHideBackToTopLink = () => {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            button.classList.remove('is-hidden');
        } else {
            button.classList.add('is-hidden');
        }
    }

    if (button) {
        window.onscroll = () => {
            showOrHideBackToTopLink();
        }

        showOrHideBackToTopLink();
    }
});
