// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "@shoelace-style/shoelace";

import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';

registerIconLibrary('fa', {
    resolver: name => {
        const filename = name.replace(/^fa[rbs]-/, '');
        let folder = 'regular';
        if (name.substring(0, 4) === 'fas-') folder = 'solid';
        if (name.substring(0, 4) === 'fab-') folder = 'brands';
        return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.1/svgs/${folder}/${filename}.svg`;
    },
    mutator: svg => svg.setAttribute('fill', 'currentColor')
});

import "./parts/remove-no-js-class";
import "./parts/site-title";
import "./parts/fact-circle";
import "./parts/back-to-top-link";
import "./parts/mobile-nav";
import "./parts/dropdown-navigation";
import "./parts/home-circle";
import "./parts/photoswipe-lightbox";
import "./parts/field";
import "./parts/message-boxes";
import "./parts/timeago-datetimes";
import "./parts/badge";
import "./parts/admin-parks_and_coasters";
