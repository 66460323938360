import { Controller } from "@hotwired/stimulus"
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';

export default class extends Controller {
    static get targets() {
        return ["gallery", "allPhotosObject"]
    }

    connect() {
        this.element[this.identifier] = this;

        // Lay photos out in justified layout
        let images;

        this.layout();

        // Initialise Photoswipe
        if (this.hasAllPhotosObjectTarget) {
            images = this.buildPhotosObjectFromServer();
        }

        const options = {
            gallery: this.galleryTarget,
            children: 'figure',
            closeOnVerticalDrag: true,
            pswpModule: () => import('photoswipe'),
        };
        const lightbox = new PhotoSwipeLightbox(options);

        const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
            type: 'auto',
            captionContent: (slide) => {
                console.log(slide);
                return slide.data.caption;
            }
        });

        // Adds Download button to Photoswipe toolbar
        lightbox.on('uiRegister', function() {
            lightbox.pswp.ui.registerElement({
                name: 'download-button',
                order: 8,
                isButton: true,
                tagName: 'a',

                // SVG with outline
                html: {
                    isCustomSVG: true,
                    inner: '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1-1.5-1.6ZM23 23H9v2h14" id="pswp__icn-download"/>',
                    outlineID: 'pswp__icn-download'
                },

                onInit: (el, pswp) => {
                    el.setAttribute('download', '');
                    el.setAttribute('target', '_blank');
                    el.setAttribute('rel', 'noopener');

                    pswp.on('change', () => {
                        el.href = pswp.currSlide.data.src;
                    });
                }
            });
        });

        if (this.hasAllPhotosObjectTarget) {
            // Filter the total amount of slides,
            // so it's based on "images" array
            lightbox.addFilter('numItems', (numItems) => {
                return images.length;
            });

            // Filter the data about each slide
            // if slide element is in DOM - define `element` and
            // `msrc` properties
            lightbox.addFilter('itemData', (itemData, index) => {
                const galleryEl = lightbox.options.dataSource.gallery;
                const element = galleryEl.querySelector('[data-id="' + images[index].id + '"]');
                if (element) {
                    return {
                        element: element,
                        msrc: element.querySelector('img').src,
                        ...images[index]
                    };
                }
                return images[index];
            });

            lightbox.addFilter('thumbEl', (thumbEl, data, index) => {
                const el = this.galleryTarget.querySelector('[data-id="' + data.id + '"] img');
                if (el) {
                    return el;
                }
                return thumbEl;
            });


            lightbox.addFilter('placeholderSrc', (placeholderSrc, slide) => {
                const el = this.galleryTarget.querySelector('[data-id="' + slide.data.id + '"] img');
                if (el) {
                    return el.src;
                }
                return placeholderSrc;
            });

            // Filter the clicked index,
            // so it's based on images array, instead of elements in DOM
            lightbox.addFilter('clickedIndex', (clickedIndex, e) => {
                /*
                    we need to find index of
                    gallery item based on clicked target

                    clickedIndex is index based
                    on the current DOM elements

                    e.target is clicked target

                    e.currentTarget is always `gallery` element
                    (#gallery--mixed-source element)
                */

                // query all children
                const childElements = e.currentTarget.querySelectorAll(lightbox.options.children);

                // get clicked child
                if (childElements[clickedIndex]) {
                    // get ID of clicked child
                    const id = parseInt(childElements[clickedIndex].dataset.id, 10);

                    // get index based on ID
                    const index = images.findIndex(image => image.id === id);
                    if (index >= 0) {
                        return index;
                    }
                }

                return clickedIndex;
            });
        }

        lightbox.init();
    }

    layout() {
        let targetRowHeight = 100;

        if (window.innerWidth > 500) {
            targetRowHeight = 200;
        }

        let ellll = this.galleryTarget;

        let width = this.galleryTarget.offsetWidth
        let layoutGeometry = require('justified-layout')(this.buildPhotosObjectFromPage(this.galleryTarget), {
            'containerWidth': width,
            'containerPadding': 0,
            'targetRowHeight': targetRowHeight,
            'boxSpacing': 10
        })

        // Sets height of gallery wrapper element
        this.galleryTarget.style.height = layoutGeometry.containerHeight + 'px'

        // Absolutely positions each image within the gallery wrapper according to Justified Layout calculations
        layoutGeometry.boxes.map(function(box, i) {
            let num = i + 1
            let figure = ellll.querySelector('figure:nth-of-type(' + num + ')')
            figure.setAttribute('data-index', i)
            figure.style.width = box.width + 'px'
            figure.style.height = box.height + 'px'
            figure.style.top = box.top + 'px'
            figure.style.left = box.left + 'px'
        })
    }

    buildPhotosObjectFromPage(gallery) {
        let thumbElements = gallery.children
        let items = []
        let figureEl
        let linkEl
        let item

        // Loop over all photos
        for (let i = 0; i < thumbElements.length; i++) {
            figureEl = thumbElements[i] // <figure>
            if (figureEl.querySelector('a')) {
                linkEl = figureEl.querySelector('a') // <a>
            }

            // Build slide object
            item = {
                id: figureEl.getAttribute('data-id'),
                width: parseInt(figureEl.getAttribute('data-pswp-width'), 10),  // Used for justified-layout
                height: parseInt(figureEl.getAttribute('data-pswp-height'), 10),  // Used for justified-layout
            }

            items.push(item)
        }

        return items;
    }

    buildPhotosObjectFromServer() {
        return JSON.parse(this.allPhotosObjectTarget.textContent)
    }
}
