import { Controller } from "@hotwired/stimulus";

let badVals = ["", "", null, 0, "0"];
let notInUseFields = [];

export default class extends Controller {
    static targets = ["form", "riddenin", "newinyear", "CoasterAttributes", "CoasterAttributesVisible"];

    connect() {}

    submit(event) {
        console.log('submit fired');
        // Disables all inputs not filled in. Submits the form so only submitted fields are sent, then re-enables all fields.

        // Iterates all form fields, sets disabled attribute if value is a bad value
        this.formTarget.querySelectorAll("input, select, sl-select, sl-switch, sl-radio-group, textarea").forEach((el) => {
            if (!el.disabled) {
                if (badVals.includes(el.value)) {
                    // If field is not used
                    notInUseFields.push(el);
                    el.disabled = true;
                }
            }
        });

        this.CoasterAttributesVisibleTarget.disabled = true
        notInUseFields.push(this.CoasterAttributesVisibleTarget);

        this.formTarget.requestSubmit();

        // Removes disabled attribute on all fields
        notInUseFields.forEach((el) => {
            el.disabled = false;
        });
    }

    // each time multi-select is changes, concatenate the values and output to the hidden text field.
    // coaster_attributes() {
    //     let data = new FormData(this.formTarget);
    //     for (let [key, value] of data) {
    //         data.set(key, data.getAll(key))
    //     }

    //     let coaster_attributes = data.get('coaster_attributes_visible');

    //     this.CoasterAttributesTarget.value = coaster_attributes;

    //     this.submit();
    // }
}
