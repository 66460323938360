/**
 * Remove all child nodes from an element
 * @param {Object} element The element to empty
 */
function empty(element) {
  var children = Array.prototype.slice.call(element.childNodes);

  children.forEach(function (child) {
    element.removeChild(child);
  });
}

// function toggleItem(toggler, itemType, itemUrl, itemState, itemSource, outputDiv) {
//     empty(outputDiv);

//     let xhr = new XMLHttpRequest();
//     xhr.open('POST', itemUrl);
//     xhr.send(null);

//     xhr.onreadystatechange = function () {
//         if (xhr.readyState === 4) { // 4 means "Done"
//             if (xhr.status === 204) { // 204 means processed successfully with no content returned
//                 console.log('Successfully toggled');

//                 if (itemState == true) {
//                     showItem(itemType, itemSource, outputDiv);
//                 }
//             } else {
//                 console.log('Failure to toggle');
//                 toggler.toggleAttribute('checked');
//             }
//         }
//     };
// }

function showItem(itemType, itemSource, outputDiv) {
  empty(outputDiv);

  if (itemType == "photo" || itemType == "logo" || itemType == "map") {
    let img = document.createElement("img");
    img.src = itemSource;
    outputDiv.appendChild(img);
  }
}

document.addEventListener("turbo:load", function () {
  // document.querySelectorAll('.theme-park-item-toggler').forEach(function(the_toggler) {
  //     the_toggler.addEventListener('change', function(e) {
  //         let li = the_toggler.parentNode.parentNode;
  //         toggleItem(the_toggler, li.dataset.type, li.dataset.url, e.target.checked, li.dataset.source, li.querySelector('.theme-park-item-toggler-output'));
  //     });
  // });

  document.querySelectorAll("button.show-asset").forEach(function (button) {
    button.addEventListener("click", function (e) {
      let li = button.parentNode.parentNode;
      showItem(li.dataset.type, li.dataset.source, button.parentNode);
    });
  });
});
