import { Controller } from "@hotwired/stimulus";

let badVals = ["", "", null, 0, "0"];
let notInUseFields = [];

export default class extends Controller {
    static targets = ["form", "riddenin", "newinyear", "CoasterAttributes", "CoasterAttributesVisible", "RideAttributes", "RideAttributesVisible", "filterdialog", "title"];

    connect() {}

    toggle() {
        this.filterdialogTarget.show();
    }

    submit(event) {
        console.log('submit fired');
        // Disables all inputs not filled in. Submits the form so only submitted fields are sent, then re-enables all fields.

        // Iterates all form fields, sets disabled attribute if value is a bad value
        this.formTarget.querySelectorAll("input, select, sl-select, sl-checkbox, sl-switch, sl-radio-group, textarea").forEach((el) => {
            if (!el.disabled) {
                if (badVals.includes(el.value)) {
                    // If field is not used
                    notInUseFields.push(el);
                    el.disabled = true;
                }
            }
        });

        if (this.hasCoasterAttributesVisibleTarget) {
            this.CoasterAttributesVisibleTarget.disabled = true
            notInUseFields.push(this.CoasterAttributesVisibleTarget);
        }

        if (this.hasRideAttributesVisibleTarget) {
            this.RideAttributesVisibleTarget.disabled = true
            notInUseFields.push(this.RideAttributesVisibleTarget);
        }

        this.formTarget.requestSubmit();

        // this.filterdialogTarget.hide();

        // Removes disabled attribute on all fields
        notInUseFields.forEach((el) => {
            el.disabled = false;
        });
    }

    // each time multi-select is changes, concatenate the values and output to the hidden text field.
    coaster_attributes() {
        let data = new FormData(this.formTarget);
        for (let [key, value] of data) {
            data.set(key, data.getAll(key))
        }

        let coaster_attributes = data.get('coaster_attributes_visible');

        this.CoasterAttributesTarget.value = coaster_attributes;

        this.submit();
    }

    // each time multi-select is changes, concatenate the values and output to the hidden text field.
    ride_attributes() {
        let data = new FormData(this.formTarget);
        for (let [key, value] of data) {
            data.set(key, data.getAll(key))
        }

        let ride_attributes = data.get('ride_attributes_visible');

        this.RideAttributesTarget.value = ride_attributes;

        this.submit();
    }
}
