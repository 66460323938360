import PhotoSwipeLightbox from 'photoswipe/lightbox';

document.addEventListener("turbo:load", function() {
    const lightbox = new PhotoSwipeLightbox({
        // may select multiple "galleries"
        gallery: '.gallery a',

        // Elements within gallery (slides)
        // children: 'a',

        // setup PhotoSwipe Core dynamic import
        pswpModule: () => import('photoswipe')
    });
    lightbox.init();
})
