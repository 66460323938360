import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { MarkerManager } from '@googlemaps/markermanager'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [""];
    static values = { type: String,
                      id: Number,
                      name: String,
                      latitude: Number,
                      longitude: Number
                    };

    connect() {
        const loader = new Loader({
            apiKey: "AIzaSyCNgAa4setX43gtR_kIZ8Kf7im7SXe6Mm0",
            version: "weekly"
        });

        let dialogContainer = document.querySelector("#dialog-container");

        loader.load().then(async () => {
            const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

            function calculateCenter() {
                center = map.getCenter();
            }

            let center;
            let worldCenter = new window.google.maps.LatLng(32.249974, 5.800781);
            let mapOptions = {
                mapId: "MY_MAP",
                center: worldCenter,
                zoom: 3,
                scrollwheel: false,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: true,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                rotateControl: true,
                fullscreenControl: true,
                controlSize: 32
            };

            let map = new google.maps.Map(this.element, mapOptions);
            let mgr = new MarkerManager(map, 20);
            let infoWindow = new google.maps.InfoWindow();
            let markers = [];

            if (this.hasTypeValue) {
                let marker;
                let markerOptions;
                let infoWindowContent;
                let location;
                // const venueIcon = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue.png';

                if (this.hasLatitudeValue && this.hasLongitudeValue) {
                    location = new google.maps.LatLng(this.latitudeValue, this.longitudeValue);
                } else {
                    location = worldCenter;
                }

                if (this.typeValue == 'attraction' || this.typeValue == 'venue') {
                    mapOptions["mapTypeId"] = google.maps.MapTypeId.SATELLITE;
                    mapOptions["zoom"] = 19;
                    mapOptions["center"] = location;
                    map.setOptions(mapOptions);

                    const coasterGlyphImg = document.createElement('img');
                    coasterGlyphImg.src = RAILS_ASSET_URL("coaster-white.svg");

                    const parkGlyphImg = document.createElement('img');
                    parkGlyphImg.src = RAILS_ASSET_URL("castle-white.svg");

                    const pin_style = new PinElement({
                        scale: 1.2,
                        glyph: (this.typeValue == 'attraction') ? coasterGlyphImg : parkGlyphImg,
                        background: "#1C5F90",
                        borderColor: "#ffffff"
                    });

                    markerOptions = {
                        position: location,
                        title: this.nameValue,
                        map: map,
                        content: pin_style.element
                    };

                    marker = new AdvancedMarkerElement(markerOptions);
                }

                if (this.typeValue == 'trip') {
                    fetch('/api/trips/' + this.idValue + '.json?show_visits=1')
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);
                        let markerLocations = [];
                        let bounds = new google.maps.LatLngBounds();
                        let trip_name = data.name;

                        data.visits.forEach(visit => {
                            let venuePosition = new google.maps.LatLng(visit.venue_lat, visit.venue_lng);

                            let venueMarker = new AdvancedMarkerElement({
                                position: venuePosition,
                                title: visit.venue
                            });

                            markers.push(venueMarker);
                            markerLocations.push(venuePosition);
                        });

                        markers.forEach(marker => {
                            marker.setMap(map);
                            bounds.extend(marker.getPosition());
                        });

                        let polyline = new google.maps.Polyline({
                            path: markerLocations,
                            geodesic: true,
                            strokeColor: "#ff0000",
                            strokeOpacity: 1.0,
                            strokeWeight: 3,
                            map: map
                        });

                        map.fitBounds(bounds);
                    });
                }
            } else {
                // Default map of all venues and attractions when zoomed

                // Add Parks
                fetch('/api/venues.json')
                .then(response => response.json())
                .then(data => {
                    const markerDataMap = new Map();

                    data.forEach(venue => {
                        if (venue.lat && venue.lng) {
                            let title = venue.name;
                            let position = new google.maps.LatLng(venue.lat, venue.lng);
                            // let icon = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue.png';

                            const glyphImg = document.createElement('img');
                            glyphImg.src = RAILS_ASSET_URL("castle-white.svg")

                            const pin_style = new PinElement({
                                scale: 1.2,
                                glyph: glyphImg,
                                background: "#1C5F90",
                                borderColor: "#ffffff"
                            });

                            let venueMarker = new AdvancedMarkerElement({
                                map: map,
                                position: position,
                                title: title,
                                content: pin_style.element,
                                // collisionBehavior: google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY
                            });

                            markerDataMap.set(venueMarker, venue.info_window_content);

                            google.maps.event.addListener(venueMarker, 'click', function() {
                                let dialogContent = markerDataMap.get(venueMarker);
                                document.querySelector("#map div:first-of-type").appendChild(dialogContainer);
                                dialogContainer.innerHTML = dialogContent;

                                let dialog = dialogContainer.querySelector("sl-dialog");
                                dialog.show();
                                dialog.addEventListener("sl-after-hide", () => {
                                    document.body.appendChild(dialogContainer);
                                });
                            });

                            markers.push(venueMarker);
                        }
                    });

                    // let markerCluster = new MarkerClusterer({map, markers});

                    // Add Coasters at zoom level 15
                    // google.maps.event.addListener(mgr, "loaded", function() {
                    //     fetch('/api/attractions.json')
                    //     .then(response => response.json())
                    //     .then(data => {
                    //         data.forEach(attraction => {
                    //             if (attraction.lat && attraction.lng) {
                    //                 let title = attraction.name;
                    //                 let position = new google.maps.LatLng(attraction.lat, attraction.lng);
                    //                 let icon = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red.png'
                    //                 let attractionMarker = new google.maps.Marker({
                    //                     position: position,
                    //                     title: title,
                    //                     icon: icon
                    //                 });

                    //                 mgr.addMarker(attractionMarker, 15);

                    //                 let attractionIWContent = attraction.info_window_content;

                    //                 attractionMarker.addListener('click', (function(marker, venueIWContent) {
                    //                     return function() {
                    //                         infoWindow.setContent(attractionIWContent);
                    //                         infoWindow.open(map, attractionMarker);
                    //                     };
                    //                 })(attractionMarker, attractionIWContent));
                    //             }
                    //         });

                    //         mgr.refresh();
                    //     });
                    // });
                });
            }
        });
    }
}
