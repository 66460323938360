import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "submodelTitle"];

  connect() {
    this.items = Array.from(this.element.querySelectorAll(".item-grid .attraction"));
    this.model = this.element.dataset.filterModel;
  }

  apply(event) {
    const submodel = event.target.dataset.filterSubmodel;
    const model = event.target.dataset.filterModel;
    const count = event.target.dataset.filterCount;

    console.log("Applying filter:", model, submodel);

    document.startViewTransition(() => {

      // Only apply filtering if the clicked button belongs to the current model
      if (model !== this.model) return;

      this.items.forEach((item) => {
        const matchesSubmodel = submodel === "All" || (submodel === "Others" && (!item.dataset.submodel || item.dataset.submodel === "")) || item.dataset.submodel === submodel;

          item.style.display = matchesSubmodel ? "block" : "none";

          this.submodelTitleTarget.textContent = submodel + ' (' + count + ')';
      });

      this.reorganize();

    });
  }

  reorganize() {
    // Optional: If you use a layout engine like Masonry or Grid.js, trigger a layout update here.
  }
}
