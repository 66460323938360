document.addEventListener("turbo:load", function() {
    let title = document.querySelector('.header__site-title')

    if (title) {
        title.addEventListener('mouseover', () => {
            title.classList.add('animate__animated', 'animate__tada')
        });

        title.addEventListener('animationend', () => {
            title.classList.remove('animate__animated', 'animate__tada')
        });
    }
})

