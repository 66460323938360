document.addEventListener("turbo:load", function () {
    let mobileNav = false;
    const keycode_esc = 27;

    function ToggleMobileNav() {
        if (mobileNav) {
            // Hide mobile nav
            setTimeout(function() {
                document.querySelector('.mobile-nav').classList.remove('is-visible');
            }, 300);
            document.body.classList.remove('has-mobile-nav');
            document.querySelector('.header__mobile-toggle').removeAttribute("aria-expanded");
            mobileNav = false;
        } else {
            // Show mobile nav
            // Add this class to ensure the nav item is displayed block before we animate it in
            document.querySelector('.mobile-nav').classList.add('is-visible');

            // Delay everything else very very slightly to allow the above to take effect.
            setTimeout(function() {
                document.body.classList.add('has-mobile-nav');
                document.querySelector('.header__mobile-toggle').setAttribute("aria-expanded", "true");
            }, 1);
            mobileNav = true;
        }
    }

    document.querySelector('.header__mobile-toggle').addEventListener('click', () => {
        ToggleMobileNav();
    })

    document.addEventListener('keyup', (e) => {
        if (e.keyCode == keycode_esc) {
            if (mobileNav == true) {
                ToggleMobileNav();
            }
        }
    })
})
