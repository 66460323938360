import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static get targets() {
        return ["versionsDialog", "versionsDialogShowButton", "versionsDialogHideButton"];
    }

    showVersionsDialog() {
        this.versionsDialogTarget.showModal();
    }

    hideVersionsDialog() {
        this.versionsDialogTarget.close();
    }

    checkForBackdropClose(event) {
        var rect = this.versionsDialogTarget.getBoundingClientRect();
        var isInDialog=(rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
        if (!isInDialog) {
            this.hideVersionsDialog();
        }
    }
}
